import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import copy from 'v-copy';
import i18n from './i18n/i18n'; //i18n国际化

Vue.config.productionTip = false;

import ElementUI from "element-ui";
import Element from "element-ui";
import "element-ui/lib/theme-chalk/index.css";

import axios from "axios";
import { loadDynamicTranslations,getAppTitle } from "@/utils";

//全局样式
import "@/assets/css/reset.css";

// 第二个axios实例，指向另一个后端地址
const secondaryAxios = axios.create({
  baseURL: "https://www.u4unlock.cn/wxapi",
});

Vue.prototype.$axiosSecondary = secondaryAxios;

Vue.prototype.$message = ElementUI.Message;
Vue.use(ElementUI);
Vue.use(Element, { size: "small", zIndex: 3000 });
Vue.use(copy);


loadDynamicTranslations().then(dynamicTranslations=>{
  try {
    const dynamicEnMessages = dynamicTranslations.filter(x=>x.language === 'en')[0]
    const dynamicZhCNMessages = dynamicTranslations.filter(x=>x.language === 'zh_CN')[0]
    const mergedEnMessages = {...i18n.messages.en,...dynamicEnMessages.data}
    const mergedZhCNMessages = {...i18n.messages.zh,...dynamicZhCNMessages.data}

    i18n.setLocaleMessage('en',mergedEnMessages)
    i18n.setLocaleMessage('zh',mergedZhCNMessages)

    i18n.locale = localStorage.getItem('language') || 'zh'
  }catch (e) {
    console.log('后台动态语言配置加载失败')
  }
})


getAppTitle().then(title => {
store.commit('setAppTitle', title);
}).finally(() => {
  new Vue({
    i18n, //i18n国际化
    router,
    store,
    render: (h) => h(App),
  }).$mount("#app");
})

// src/apiService.js

import axios from "axios";
import storage from "store";
import appConfig from "@/config/appConfig";
import Vue from "vue";
import router from "../router";
// import { isMobile } from "@/utils";
let timer = null;

const apiService = {
  // 基础URL，根据实际情况设置
  baseURL: process.env.VUE_APP_API_URL || "https://www.u4unlock.cn/userapi/",
  // baseURL: process.env.VUE_APP_API_URL || 'http://localhost:8181',
  // 请求实例
  axiosInstance: axios.create({
    //http://localhost:8181
    baseURL: "https://www.u4unlock.cn/userapi/",
    // baseURL: 'http://localhost:8181',
    timeout: 15000, // 可选，设置超时时间
    headers: {
      "Content-Type": "application/json",
      iToken: "",
    },
  }),

  // 请求拦截器
  interceptors: {
    requestInterceptor: (config) => {
      // 在这里可以添加全局请求头、处理身份验证等操作
      // 确保 config.headers 存在
      config.headers = config.headers || {};
      const token = storage.get(appConfig.ACCESS_TOKEN_NAME);
      if (
        token &&
        config.url !== "/generatePcQrloginUrl" &&
        config.url !== "/pcQrlogin"
      ) {
        // 除登录接口外，添加token
        config.headers.iToken = token;
      }
      return config;
    },
  },

  // 封装GET请求
  get: (url, params = {}) => {
    return new Promise((resolve, reject) => {
      let config = {
        params,
      };
      config = apiService.interceptors.requestInterceptor(config);
      apiService.axiosInstance
        .get(url, config)
        .then((response) => {
          const { status, message, data } = response;
          if (status === 200) {
            const { code, msg } = data;
            if (code === 1001) {
              // 扫码登录
              resolve(data);
              return;
            }
            if (code !== 0) {
              Vue.prototype.$message.error(msg);
              reject(msg);
              return;
            }
          }

          if (status === 401) {
            // Vue.prototype.$message.error("检测到您未登陆，即将跳转登录页面！");
            apiService.jumpPage();
            reject(message);
          }
          resolve(data);
        })
        .catch((response) => {
          const { status, message } = response;
          if (status === 401) {
            // Vue.prototype.$message.error("检测到您未登陆，即将跳转登录页面！");
            apiService.jumpPage();
            reject(message);
          }
          reject(response);
        });
    });
  },

  // 封装POST请求
  post: async (url, data = {}, config = {}) => {
    return new Promise((resolve, reject) => {
      config.data = data;

      config = apiService.interceptors.requestInterceptor(config);

      apiService.axiosInstance
        .post(url, config.data, config)
        .then((response) => {
          const { status, message, data } = response;
          if (status === 200) {
            const { code, msg } = data;
            if (code === 1001) {
              // 扫码登录
              resolve(data);
              return;
            }
            if (code !== 0) {
              Vue.prototype.$message.error(msg);
              reject(msg);
              return;
            }
          }

          if (status === 401) {
            // Vue.prototype.$message.error("检测到您未登陆，即将跳转登录页面！");
            apiService.jumpPage();
            reject(message);
            return;
          }
          resolve(data);
        })
        .catch((response) => {
          const { status, message } = response;
          if (status === 401) {
            // Vue.prototype.$message.error("检测到您未登陆，即将跳转登录页面！");
            apiService.jumpPage();
            reject(message);
          }
          reject(response);
        });
    });
  },

  // 跳转登录页面
  jumpPage: () => {
    if (timer) clearTimeout(timer);
    timer = setTimeout(() => {
      const { fullPath } = router.currentRoute;
      // alert(fullPath);
      // alert(
      //   !["login-authorize", "mailRegister", "register"].some(
      //     (u) => fullPath.indexOf(u) != -1
      //   )
      // );
      if (
        !["login-authorize", "mailRegister", "register"].some(
          (u) => fullPath.indexOf(u) != -1
        )
      ) {
        // router.push({ path: isMobile() ? "/wechatAuth" : "/login" });
      }
    }, 100);
  },
};

export default apiService;

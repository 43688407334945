<template>
  <div class="float-btn">
    <!-- <div class="item" @click="$router.push('/home')">
      <img src="@/assets/images/logo_icon_03.jpg" alt="" />
    </div> -->
    <div class="item" @click="$router.go(0)">刷新</div>
    <div class="item" @click="$router.replace('/home')">返回</div>
  </div>
</template>
<script>
export default {

}
</script>
<style lang="scss" scoped>
.float-btn {
  position: fixed;
  bottom: 150px;
  right: 10px;
  z-index: 10;

  .item {
    background-color: #f4f4f5;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 1px solid #ccc;
    color: #666;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    margin-bottom: 5px;

    img {
      width: 30px;
    }
  }
}
</style>
import Vue from "vue";
import Vuex from "vuex";
import apiService from "@/api/apiService";
import avator from "@/assets/images/avator_03.jpg";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    showOrderSearchDialog: false,
    showWorkOrderDialog: false,
    id: "",
    roleName: "",
    advancedUser: "",
    email: "",
    point: 0,
    appTitle: "U4快查",
    token: "",
    signUrl: "",
    userInfo: "",
    orderInfo: "",
    showStatus: "",
    showNotice: false,
    star: [],
  },
  getters: {
    getSignUrl(state) {
      return state.signUrl;
    },
    getAppTitle(state) {
      return state.appTitle;
    },
    getShowOrderSearchDialog(state) {
      return state.showOrderSearchDialog;
    },
    getShowWorkOrderDialog(state) {
      return state.showWorkOrderDialog;
    },
    getId(state) {
      return state.id;
    },
    getPoint(state) {
      return state.point;
    },
    getAdvancedUser(state) {
      return state.advancedUser;
    },
    getEmail(state) {
      return state.email;
    },
    getToken(state) {
      return state.token;
    },
    getUserInfo(state) {
      return state.userInfo;
    },
    getOrderInfo(state) {
      return state.orderInfo;
    },
  },
  mutations: {
    setSignUrl(state, value) {
      state.signUrl = value;
    },
    setAppTitle(state, show) {
      state.appTitle = show;
    },
    setShowOrderSearchDialog(state, show) {
      state.showOrderSearchDialog = show;
    },
    setShowWorkOrderDialog(state, show) {
      state.showWorkOrderDialog = show;
    },
    setId(state, show) {
      state.id = show;
    },
    setPoint(state, show) {
      state.point = show;
    },
    setRoleName(state, show) {
      state.roleName = show;
    },
    setAdvancedUser(state, show) {
      state.advancedUser = show;
    },
    setEmail(state, show) {
      state.email = show;
    },
    setUserInfo(state, val) {
      state.userInfo = val;
    },
    setOrderInfo(state, val) {
      state.orderInfo = val;
    },
    setShowModal(state, val) {
      state.showStatus = val;
    },
    setShowNotice(state, val) {
      state.showNotice = val;
    },
    setStar(state, val) {
      state.star = val;
      // let star = JSON.parse(localStorage.getItem("star")) ?? [];
      // console.log(star);
      // if (star && star.includes(val)) {
      //   const starFilter = star.filter((u) => u !== val);
      //   state.star = starFilter;
      //   localStorage.setItem("star", JSON.stringify(starFilter));
      // } else {
      //   star.push(val);
      //   state.star = star;
      //   localStorage.setItem("star", JSON.stringify(star));
      // }
    },
  },
  actions: {
    getCurrentUserInfo(context) {
      return new Promise((resolve, reject) => {
        apiService
          .get("/currentUserInfo")
          .then((res) => {
            if (!res.data.headimgurl) {
              res.data.headimgurl = avator;
            }
            resolve(res.data);
            context.commit("setUserInfo", res.data);
          })
          .catch(() => {
            reject({});
          });
      });
    },
    async getStar(context, val) {
      if (val && !this.state.star.includes(val)) {
        await apiService.post("/collection", {
          serviceId: val,
        });
        this.state.star.push(val);
        Vue.prototype.$message.success("收藏成功");
      } else if (val && this.state.star.includes(val)) {
        await apiService.post("/cancelCollection", {
          serviceId: val,
        });
        this.state.star = this.state.star.filter((u) => u !== val);
        Vue.prototype.$message.success("取消收藏成功");
      } else {
        apiService
          .get("/collectionService")
          .then((res) => {
            let data = res.data ?? [];
            data = data.map((u) => u.id + "");
            context.commit("setStar", data);
          })
          .catch(() => {});
      }
    },
  },
  modules: {},
});
